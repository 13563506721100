import React from 'react'
import {
  BlockList,
  generateBackgroundStyles,
  generateColorThemeClasses,
  generateDarkenLightenStyles,
  generateForegroundStyles,
  generatePaddingClasses,
  generateColumnClasses,
  generateTopRadiusClasses,
  SectionSettingsProps,
} from '.'

export const SectionSingleColumnBlock = (props: SectionSettingsProps) => {
  const { areas } = props

  const contentArea = areas?.find(area => area.name === 'Content')

  const backgroundStyles = generateBackgroundStyles(props)
  const darkenLightenStyles = generateDarkenLightenStyles(props)
  const colorThemeClasses = generateColorThemeClasses(props)
  const paddingClasses = generatePaddingClasses(props)
  const foregroundStyles = generateForegroundStyles(props)
  const colClass = generateColumnClasses({ ...props, sectionType: 'single' })
  const topRadiusClasses = generateTopRadiusClasses(props)

  return (
    <div
      style={{ ...backgroundStyles, ...foregroundStyles }}
      className={`${colorThemeClasses} ${topRadiusClasses}`}
      data-block-section>
      <div style={darkenLightenStyles} className={paddingClasses}>
        <div className="container">
          <div className="row">
            {contentArea && (
              <div className={`col-12 ${colClass.firstCol}`}>
                <BlockList blocks={contentArea.blocks} availableColumns={12} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
